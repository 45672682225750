import {
    Component,
    OnInit,
    Input,
    OnChanges,
    EventEmitter,
    Output,
    SimpleChanges,
    ElementRef,
    ViewEncapsulation,
    HostListener,
    ChangeDetectionStrategy,
    Inject
} from '@angular/core';
import { PDFViewerParams, PDFDocumentProxy } from 'pdfjs-dist';
import { getVisibleElements } from 'pdfjs-dist/lib/web/ui_utils';

import { PageRotationEvent } from '../models/page-rotation-event';
import { SearchEvent } from '../models/search-event';
import { PageEvent } from '../models/page-event';
import { LinkService, RenderingQueue } from '../services/tokens';

import * as PDFJSViewer from 'pdfjs-dist/web/pdf_viewer';

@Component({
    selector: 'pdf-viewer-viewer',
    template: `
        <div id="viewerContainer" tabindex="0">
            <div id="viewer" class="pdfViewer"></div>
        </div>
    `,
    styleUrls: ['./viewer.component.css'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewerComponent implements OnInit, OnChanges {

    @Input() proxy: PDFDocumentProxy;
    @Input() currentPage: number;
    @Input() scale: number | 'page-width' | 'page-height' | 'page-fit' | 'auto';

    @Output() currentPageChange = new EventEmitter<number>();
    @Output() scaleChange = new EventEmitter<number>();
    @Output() pagesInit = new EventEmitter<any>();
    @Output() pagesLoaded = new EventEmitter<any>();

    pdfViewer: any;
    findController: any;
    container: any;
    eventBus: any;

    constructor(
        private element: ElementRef,
        @Inject(LinkService) private linkService: any,
        @Inject(RenderingQueue) private renderingQueue: any
    ) { }

    ngOnInit() {
        this.eventBus = new PDFJSViewer.EventBus();

        this.eventBus.on('pagechanging', e => this.onPageChanged(e));
        this.eventBus.on('pagesinit', e => this.onPagesInit(e));
        this.eventBus.on('pagesloaded', e => this.onPagesLoaded(e));

        this.setupViewer();
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('proxy' in changes) {
            this.render();
        }
        if ('currentPage' in changes) {
            this.setCurrentPage();
        }
        if ('scale' in changes) {
            this.setCurrentScale();
        }
    }

    onPageChanged(e) {
        if (this.currentPage !== e.pageNumber) {
            this.currentPageChange.emit(e.pageNumber);
        }
    }

    onPagesInit(e) {
        this.pdfViewer._pages.forEach(pageView => {
            this.bindOnBeforeDraw(pageView);
            this.disablePageLinks(pageView);
        });

        this.setCurrentScale();
        this.renderingQueue.renderHighestPriority();

        if (this.proxy && window.location.hash) {
            this.linkService.setHash(window.location.hash.substr(1));
        }

        this.pagesInit.emit(e);
    }

    onPagesLoaded(e) {
        if (this.currentPage !== this.pdfViewer.currentPageNumber) {
            this.currentPageChange.emit(this.pdfViewer.currentPageNumber);
        }
        this.pagesLoaded.emit(e);
    }

    rotatePage(event: PageRotationEvent) {
        if (!this.proxy) { return; }
        const pageView = this.pdfViewer._pages[event.pageNumber - 1];
        const rotation = (pageView.rotation + 360 + event.rotation) % 360;
        pageView.update(pageView.scale, rotation);
        this.pdfViewer.update();
    }

    rotateAllPages(rotation: number) {
        if (!this.proxy) { return; }
        this.pdfViewer._pages.forEach(pageView => {
            const delta = (pageView.rotation + 360 + rotation) % 360;
            pageView.update(pageView.scale, delta);
        });
        this.pdfViewer.update();
    }

    removePage(event: PageEvent) {
        if (!this.proxy) { return; }
        const pageView = this.pdfViewer._pages[event.pageNumber - 1];
        this.hidePage(pageView);
        this.pdfViewer.update();
    }

    search(event: SearchEvent) {
        const command = event.again ? 'findagain' : 'find';
        this.findController.executeCommand(command, {
            caseSensitive: false,
            findPrevious: event.findPrevious,
            highlightAll: true,
            phraseSearch: true,
            query: event.query || ''
        });
    }

    reload() {
        const pages = this.element.nativeElement.querySelectorAll('.page');
        pages.forEach(x => x.classList.add('reloading'));
    }

    private setupViewer() {
        this.container = this.element.nativeElement.querySelector('div#viewerContainer');

        this.findController = new PDFJSViewer.PDFFindController({
            eventBus: this.eventBus,
            linkService: this.linkService,
        });

        const pdfOptions: PDFViewerParams | any = {
            container: this.container,
            eventBus: this.eventBus,
            removePageBorders: false,
            linkService: this.linkService,
            textLayerMode: 1,
            enhanceTextSelection: true,
            renderingQueue: this.renderingQueue,
            findController: this.findController
        };

        this.pdfViewer = new PDFJSViewer.PDFViewer(pdfOptions);
        this.linkService.setViewer(this.pdfViewer);
        this.renderingQueue.setViewer(this.pdfViewer);

        this.pdfViewer['_getVisiblePages'] = () => {
            const pages = this.pdfViewer._pages.filter(x => !x.pdfPage || !x.pdfPage._pageInfo.removed);
            return getVisibleElements(this.pdfViewer.container, pages, true, false);
        };
    }

    private render() {
        if (this.pdfViewer) {
            this.pdfViewer.setDocument(null);
            this.pdfViewer.setDocument(this.proxy);
        }

        if (this.linkService) {
            this.linkService.setDocument(null, null);
            this.linkService.setDocument(this.proxy, null);
        }

        if (this.findController) {
            this.findController._reset();
        }
    }

    private setCurrentPage() {
        if (this.pdfViewer) {
            const currentPage = this.pdfViewer.currentPageNumber;
            if (currentPage !== this.currentPage) {
                this.pdfViewer.currentPageNumber = this.currentPage;
            }
        }
    }

    private setCurrentScale() {
        if (this.pdfViewer) {
            this.pdfViewer._setScale(this.scale, true);
            const scale = this.pdfViewer.currentScale;
            if (scale !== this.scale) {
                this.scaleChange.emit(scale);
            }
        }
    }

    private bindOnBeforeDraw(pageView: any) {
        const onBeforeDraw = pageView.onBeforeDraw;
        pageView.onBeforeDraw = () => {
            if (pageView.pdfPage._pageInfo.removed) {
                this.hidePage(pageView);
            }
            onBeforeDraw.call(pageView);
        };
    }

    private hidePage(pageView: any) {
        pageView.div.style.display = 'none';
    }

    private disablePageLinks(pageView: any) {
        pageView.annotationLayerFactory = null;
    }
}
