import {
    Component,
    ViewEncapsulation,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges,
    OnDestroy,
    ChangeDetectionStrategy
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TooltipConfig } from 'ngx-bootstrap/tooltip';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { findIndex } from 'lodash-es';

import { PageRotationEvent } from '../models/page-rotation-event';
import { SearchEvent } from '../models/search-event';
import { PageEvent } from '../models/page-event';
import { PdfPage } from '../models/pdf-page';

declare var jQuery: any;

const DEFAULT_SCALE_DELTA = 1.1;
const MIN_SCALE = 0.1;
const MAX_SCALE = 10;

export function getTooltipConfig(): TooltipConfig {
    return {
        ...new TooltipConfig(),
        placement: 'bottom',
        container: 'body'
    };
}

@Component({
    selector: 'pdf-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.css'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: TooltipConfig, useFactory: getTooltipConfig }]
})
export class ToolbarComponent implements OnDestroy, OnChanges {

    @Input() disabled = false;
    @Input() numPages: number;
    @Input() pages: PdfPage[];
    @Input() currentPage: number;
    @Input() scale: number;
    @Input() pagingPreference = ViewerPreference.enabled;
    @Input() rotatePreference = ViewerPreference.enabled;
    @Input() removePreference = ViewerPreference.enabled;
    @Input() printPreference = ViewerPreference.enabled;
    @Input() downloadPreference = ViewerPreference.enabled;

    @Output() currentPageChange = new EventEmitter<number>();
    @Output() scaleChange = new EventEmitter<number>();
    @Output() search = new EventEmitter<SearchEvent>();
    @Output() rotate = new EventEmitter<PageRotationEvent>();
    @Output() rotateAll = new EventEmitter<number>();
    @Output() remove = new EventEmitter<PageEvent>();
    @Output() reload = new EventEmitter<void>();
    @Output() print = new EventEmitter<void>();
    @Output() download = new EventEmitter<void>();

    currentPageControl: UntypedFormControl;
    searchControl: UntypedFormControl;
    isSearchOpen = false;

    get printDisabled() {
        return this.disabled || this.printPreference === ViewerPreference.disabled;
    }

    get downloadDisabled() {
        return this.disabled || this.downloadPreference === ViewerPreference.disabled;
    }

    get rotateDisabled() {
        return this.disabled || this.rotatePreference === ViewerPreference.disabled;
    }

    get removeDisabled() {
        return this.disabled || this.removePreference === ViewerPreference.disabled || this.numPages <= 1;
    }

    get pagingDisabled() {
        return this.disabled || this.pagingPreference === ViewerPreference.disabled;
    }

    get printHidden() {
        return this.printPreference === ViewerPreference.hidden;
    }

    get downloadHidden() {
        return this.downloadPreference === ViewerPreference.hidden;
    }

    get rotateHidden() {
        return this.rotatePreference === ViewerPreference.hidden;
    }

    get removeHidden() {
        return this.removePreference === ViewerPreference.hidden;
    }

    get pagingHidden() {
        return this.pagingPreference === ViewerPreference.hidden;
    }

    get adjustedPageNumber() {
        return this.getAdjustedPageNumber(this.currentPage);
    }

    get toggleMenuDisabled() {
        return this.printHidden
            && this.downloadHidden
            && this.rotateHidden
            && this.removeHidden;
    }

    private subscriptions: Subscription[] = [];

    constructor() {
        this.currentPageControl = new UntypedFormControl();
        this.searchControl = new UntypedFormControl();

        this.subscriptions.push(
            this.currentPageControl.valueChanges.pipe(
                debounceTime(250)
            ).subscribe(value => {
                this.setPage(value);
            }),
            this.searchControl.valueChanges.pipe(
                debounceTime(250)
            ).subscribe(value => {
                this.find();
            })
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('currentPage' in changes) {
            const pageNumber = this.getAdjustedPageNumber(this.currentPage);
            this.currentPageControl.setValue(pageNumber, { emitEvent: false });
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(x => x.unsubscribe());
        this.subscriptions = [];
    }

    searchClicked(e) {
        jQuery(e.target).tooltip('hide');
    }

    searchShown(e) {
        jQuery('.popover.search .form-control').focus();
    }

    setPage(pageNumberString: number | string) {
        let pageNumber = +pageNumberString;
        if (pageNumber < 1) {
            pageNumber = 1;
        } else if (pageNumber > this.numPages) {
            pageNumber = this.numPages;
        }
        pageNumber = this.getPageNumberByIndex(pageNumber - 1);
        this.currentPageChange.emit(pageNumber);
    }

    zoomIn() {
        let newScale = this.scale;
        newScale = (newScale * DEFAULT_SCALE_DELTA).toFixed(2) as any;
        newScale = Math.ceil(newScale * 10) / 10;
        newScale = Math.min(MAX_SCALE, newScale);
        this.scaleChange.emit(newScale);
    }

    zoomOut() {
        let newScale = this.scale;
        newScale = (newScale / DEFAULT_SCALE_DELTA).toFixed(2) as any;
        newScale = Math.floor(newScale * 10) / 10;
        newScale = Math.max(MIN_SCALE, newScale);
        this.scaleChange.emit(newScale);
    }

    rotatePage(rotation: number) {
        this.rotate.emit({
            pageNumber: this.currentPage,
            rotation
        });
    }

    rotateAllPages(rotation: number) {
        this.rotateAll.emit(rotation);
    }

    removePage() {
        this.remove.emit({ pageNumber: this.currentPage });
    }

    find(again = false, findPrevious = false) {
        this.search.emit({
            query: this.searchControl.value,
            again,
            findPrevious
        });
    }

    private getAdjustedPageNumber(pageNumber: number): number {
        if (!this.pages) {
            return pageNumber;
        }
        return findIndex(this.pages, x => x.pageNumber === pageNumber) + 1;
    }

    private getPageNumberByIndex(index: number): number {
        const page = this.pages[index];
        if (!page) {
            return index + 1;
        }
        return page.pageNumber;
    }
}

export enum ViewerPreference {
    enabled = 'enabled',
    disabled = 'disabled',
    hidden = 'hidden'
}
