export function renderingQueueFactory() {
    return new PDFRenderingQueue();
}

export function linkServiceFactory() {
    return new PDFLinkService();
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { HrefDirective } from './directives/href.directive';
import { NumberDirective } from './directives/number.directive';
import { ScrollToWhenDirective } from './directives/scroll-to-when.directive';

import { ToolbarComponent } from './components/toolbar.component';
import { ThumbnailsComponent } from './components/thumbnails.component';
import { PrinterComponent } from './components/printer.component';
import { PrinterProgressComponent } from './components/printer-progress.component';
import { ErrorComponent } from './components/error.component';
import { ViewerComponent } from './components/viewer.component';
import { PdfViewerComponent } from './components/pdf-viewer.component';

import { PdfService } from './services/pdf.service';
import { LinkService, RenderingQueue } from './services/tokens';

import { PDFRenderingQueue } from 'pdfjs-dist/lib/web/pdf_rendering_queue';
import { PDFLinkService } from 'pdfjs-dist/lib/web/pdf_link_service';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        ModalModule.forRoot(),
        PopoverModule.forRoot(),
        BsDropdownModule.forRoot(),
        TooltipModule.forRoot()
    ],
    declarations: [
        HrefDirective,
        NumberDirective,
        ScrollToWhenDirective,
        ToolbarComponent,
        ThumbnailsComponent,
        PrinterComponent,
        PrinterProgressComponent,
        ErrorComponent,
        ViewerComponent,
        PdfViewerComponent,
    ],
    providers: [
        PdfService,
        { provide: RenderingQueue, useFactory: renderingQueueFactory },
        { provide: LinkService, useFactory: linkServiceFactory }
    ],
    exports: [
        PdfViewerComponent
    ]
})
export class PdfViewerModule { }
