<div id="outerContainer">
    <pdf-toolbar
        [disabled]="!pdf"
        [numPages]="pdf?.numPages$ | async"
        [pages]="pdf?.pages$ | async"
        [(currentPage)]="currentPage"
        [(scale)]="scale"
        [pagingPreference]="pagingPreference"
        [rotatePreference]="rotatePreference"
        [removePreference]="removePreference"
        [printPreference]="printPreference"
        [downloadPreference]="downloadPreference"
        (search)="viewer.search($event)"
        (rotate)="rotate($event)"
        (rotateAll)="rotateAll($event)"
        (remove)="remove($event)"
        (reload)="reload();viewer.reload()"
        (print)="print()"
        (download)="download()">
    </pdf-toolbar>

    <pdf-thumbnails #thumbnails
        *ngIf="thumbnailsPreference !== 'hidden'"
        [proxy]="pdf?.proxy"
        [(currentPage)]="currentPage">
    </pdf-thumbnails>

    <pdf-viewer-viewer #viewer
        [proxy]="pdf?.proxy"
        [(currentPage)]="currentPage"
        [(scale)]="scale"
        (pagesInit)="pagesInit.emit($event)"
        (pagesLoaded)="pagesLoaded.emit($event)">
    </pdf-viewer-viewer>

    <pdf-printer-progress #printModal
        [progress]="printProgress"
        (cancel)="printer.cancel()">
    </pdf-printer-progress>

    <pdf-error
        [(error)]="error">
    </pdf-error>
</div>
<pdf-printer #printer
    [pdfViewer]="viewer.pdfViewer"
    [proxy]="pdf?.proxy"
    (beforePrint)="printProgress = 0;printModal.open()"
    (afterPrint)="printModal.close()"
    (progressChange)="printProgress = $event">
</pdf-printer>
