import {
    Component,
    Input,
    ElementRef,
    OnInit,
    OnDestroy,
    Output,
    EventEmitter,
    ViewEncapsulation,
    ChangeDetectionStrategy
} from '@angular/core';
import { PDFDocumentProxy } from 'pdfjs-dist';
import { Subscription, fromEvent } from 'rxjs';
import { PrintService, initialize, abort } from '../services/print.service';

let initialized = false;

@Component({
    selector: 'pdf-printer',
    template: `
        <div id="printContainer"></div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrinterComponent implements OnInit, OnDestroy {

    @Input() pdfViewer: any;
    @Input() proxy: PDFDocumentProxy;

    @Output() beforePrint = new EventEmitter<void>();
    @Output() progressChange = new EventEmitter<number>();
    @Output() afterPrint = new EventEmitter<void>();

    container: any;
    subscriptions: Subscription[] = [];
    scratchCanvas: HTMLCanvasElement;
    printService: PrintService;

    constructor(private element: ElementRef) {

        if (!initialized) {
            initialize();
            initialized = true;
        }

        this.subscriptions.push(
            fromEvent(window, 'beforeprint').subscribe(this.onBeforePrint),
            fromEvent(window, 'afterprint').subscribe(this.onAfterPrint)
        );
    }

    ngOnInit() {
        this.container = this.element.nativeElement.querySelector('#printContainer');
    }

    ngOnDestroy() {
        this.subscriptions.forEach(x => x.unsubscribe());
        this.subscriptions = [];
    }

    print() {
        window.print();
    }

    cancel() {
        abort();
    }

    private onBeforePrint = () => {
        if (!this.pdfViewer.pageViewsReady) {
            window.alert('Warning: The PDF is not fully loaded for printing.');
            return;
        }

        this.beforePrint.next();

        const pagesOverview = this.pdfViewer.getPagesOverview();
        this.printService = new PrintService(this.proxy, pagesOverview, this.container);
        this.printService.progress$.subscribe(x => this.progressChange.next(x));

        this.printService.layout();
    }

    private onAfterPrint = () => {
        if (this.printService) {
            this.printService.destroy();
            this.printService = null;
        }
        this.afterPrint.next();
    }
}
