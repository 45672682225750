import { PDFPageProxy } from 'pdfjs-dist';

export class PdfPage {

    constructor(public proxy: PDFPageProxy) { }

    get ref(): number {
        return this.proxy.ref.num;
    }

    get pageNumber(): number {
        return this.proxy.pageNumber;
    }

    get rotation(): number {
        return this.proxy['_pageInfo'].rotate;
    }

    set rotation(rotation: number) {
        this.proxy['_pageInfo'].rotate = rotation;
    }

    get removed(): boolean {
        return !!this.proxy['_pageInfo'].removed;
    }

    rotate(rotation: number) {
        this.rotation = (this.rotation + 360 + rotation) % 360;
    }

    remove() {
        this.proxy['_pageInfo'].removed = true;
    }
}
