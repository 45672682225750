<div class="toolbar">
    <div id="toolbarContainer" 
        [ngClass]="{
        'wide': !printHidden || !downloadHidden,
        'narrow': printHidden && downloadHidden 
        }">
        <nav class="navbar navbar-default navbar-static-top">
            <div class="container-fluid">

                <div class="navbar-left" *ngIf="!pagingHidden">
                    <div class="navbar-form navbar-left">
                        <div class="btn-group">
                            <button type="button" class="btn btn-default first hidden-xxs" tooltip="First Page" [disabled]="pagingDisabled || adjustedPageNumber <= 1"
                                (click)="setPage(1)">
                                <i class="fa fa-step-backward fa-fw"></i>
                            </button>
                            <button type="button" class="btn btn-default previous" tooltip="Previous Page" [disabled]="pagingDisabled || adjustedPageNumber <= 1"
                                (click)="setPage(adjustedPageNumber - 1)">
                                <i class="fa fa-backward fa-fw"></i>
                            </button>
                        </div>
                        <input type="number" class="form-control" [formControl]="currentPageControl" [attr.disabled]="pagingDisabled || null" />
                        <span>of {{numPages || 0}}</span>
                        <div class="btn-group">
                            <button type="button" class="btn btn-default next" tooltip="Next Page" [disabled]="pagingDisabled || adjustedPageNumber >= numPages"
                                (click)="setPage(adjustedPageNumber + 1)">
                                <i class="fa fa-forward fa-fw"></i>
                            </button>
                            <button type="button" class="btn btn-default last hidden-xxs" [disabled]="pagingDisabled || adjustedPageNumber >= numPages" tooltip="Last Page"
                                (click)="setPage(numPages)">
                                <i class="fa fa-step-forward fa-fw"></i>
                            </button>
                        </div>
                    </div>

                    <div class="navbar-form navbar-left" tooltip="Search">
                        <button type="button" class="btn btn-default search" [popover]="searchTemplate" placement="bottom"
                            container="body" containerClass="search" [outsideClick]="true" (onShown)="isSearchOpen = true; searchShown($event)"
                            (onHidden)="isSearchOpen = false; searchControl.reset();" (click)="searchClicked($event)" [class.active]="isSearchOpen"
                            [disabled]="disabled">
                            <i class="fa fa-search fa-fw"></i>
                        </button>
                    </div>
                </div>

                <div class="navbar-center">
                    <div class="navbar-form">
                        <div class="btn-group">
                            <button type="button" class="btn btn-default zoom-out" tooltip="Zoom Out" [disabled]="disabled || scale <= 0.10" (click)="zoomOut()">
                                <i class="fa fa-search-minus fa-fw"></i>
                            </button>
                            <button type="button" class="btn btn-default zoom-in" tooltip="Zoom In" [disabled]="disabled || scale >= 10" (click)="zoomIn()">
                                <i class="fa fa-search-plus fa-fw"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="navbar-right" *ngIf="!toggleMenuDisabled">
                    <div class="navbar-form navbar-left rotation" *ngIf="!rotateHidden">
                        <div class="btn-group hidden-xxs" dropdown container="body" placement="bottom right">
                            <button type="button" class="btn btn-default rotate-left" tooltip="Rotate Right" [disabled]="rotateDisabled" (click)="rotatePage(90)">
                                <i class="fa fa-rotate-right fa-fw"></i>
                            </button>
                            <button type="button" class="btn btn-default rotate-right" tooltip="Rotate Left" [disabled]="rotateDisabled" (click)="rotatePage(-90)">
                                <i class="fa fa-rotate-left fa-fw"></i>
                            </button>
                            <button type="button" class="btn btn-default dropdown-toggle rotation" dropdownToggle aria-controls="rotate" [disabled]="rotateDisabled"
                                *ngIf="numPages > 1">
                                <span class="fa fa-caret-down"></span>
                                <span class="sr-only">Toggle Dropdown</span>
                            </button>
                            <ul id="rotate" *dropdownMenu class="dropdown-menu dropdown-menu-right rotation">
                                <li>
                                    <a href class=" dropdown-item rotate-all-right" (click)="!rotateDisabled && rotateAllPages(90)">
                                        <i class="fa fa-rotate-right fw"></i>&nbsp; Rotate All Right
                                    </a>
                                </li>
                                <li>
                                    <a href class="dropdown-item rotate-all-left" (click)="!rotateDisabled && rotateAllPages(-90)">
                                        <i class="fa fa-rotate-left fw"></i>&nbsp; Rotate All Left
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="navbar-form navbar-left collapse">
                        <div class="btn-group hidden-xxs">
                            <button type="button" class="btn btn-default remove" tooltip="Remove Page" [disabled]="removeDisabled" (click)="removePage()"
                                *ngIf="!removeHidden">
                                <i class="fa fa-trash fa-fw"></i>
                            </button>
                            <button type="button" class="btn btn-default reset" tooltip="Reset" [disabled]="disabled" (click)="reload.emit()" *ngIf="!rotateHidden || !removeHidden">
                                <i class="fa fa-refresh fa-fw"></i>
                            </button>
                        </div>
                    </div>

                    <div class="navbar-form navbar-left collapse" *ngIf="!printHidden || !downloadHidden">
                        <div class="btn-group hidden-xxs">
                            <button type="button" class="btn btn-default print" tooltip="Print" [disabled]="printDisabled" (click)="print.emit()" *ngIf="!printHidden">
                                <i class="fa fa-print fa-fw"></i>
                            </button>
                            <button type="button" class="btn btn-default download" tooltip="Download" [disabled]="downloadDisabled" (click)="download.emit()"
                                *ngIf="!downloadHidden">
                                <i class="fa fa-download fa-fw"></i>
                            </button>
                        </div>
                    </div>


                    <div class="navbar-form navbar-left visible-xxs menu" *ngIf="!toggleMenuDisabled">
                        <div class="btn-group" dropdown container="body" placement="bottom right">
                            <button type="button" class="btn btn-default dropdown-toggle menu" dropdownToggle [disabled]="disabled" aria-controls="menu">
                                <i class="fa fa-bars"></i>
                                <span class="sr-only">Toggle Menu</span>
                            </button>
                            <ul id="menu" *dropdownMenu class="dropdown-menu dropdown-menu-right menu">
                                <li *ngIf="!rotateHidden" [class.disabled]="rotateDisabled">
                                    <a href class="dropdown-item rotate-right" (click)="rotatePage(90)">
                                        <i class="fa fa-rotate-right fw"></i>&nbsp; Rotate Right
                                    </a>
                                </li>
                                <li *ngIf="!rotateHidden" [class.disabled]="rotateDisabled">
                                    <a href class="dropdown-item rotate-left" (click)="rotatePage(-90)">
                                        <i class="fa fa-rotate-left fw"></i>&nbsp; Rotate Left
                                    </a>
                                </li>
                                <li role="separator" class="divider rotate" *ngIf="!rotateHidden"></li>
                                <li *ngIf="!rotateHidden && numPages > 1" [class.disabled]="rotateDisabled">
                                    <a href class=" dropdown-item rotate-all-right" (click)="!rotateDisabled && rotateAllPages(90)">
                                        <i class="fa fa-rotate-right fw"></i>&nbsp; Rotate All Right
                                    </a>
                                </li>
                                <li *ngIf="!rotateHidden && numPages > 1" [class.disabled]="rotateDisabled">
                                    <a href class="dropdown-item rotate-all-left" (click)="!rotateDisabled && rotateAllPages(-90)">
                                        <i class="fa fa-rotate-left fw"></i>&nbsp; Rotate All Left
                                    </a>
                                </li>
                                <li role="separator" class="divider rotate-all" *ngIf="!rotateHidden && numPages > 1"></li>
                                <li *ngIf="!removeHidden" [class.disabled]="removeDisabled || numPages <= 1">
                                    <a *ngIf="!removeDisabled" href class="dropdown-item remove" (click)="!removeDisabled && removePage()">
                                        <i class="fa fa-trash fw"></i>&nbsp; Remove Page
                                    </a>
                                    <span *ngIf="removeDisabled" class="dropdown-item remove disabled">
                                        <i class="fa fa-trash fw"></i>&nbsp; Remove Page
                                    </span>
                                </li>
                                <li *ngIf="!rotateHidden || !removeHidden">
                                    <a href class="dropdown-item reset" (click)="!disabled && reload.emit()">
                                        <i class="fa fa-refresh fw"></i>&nbsp; Reset
                                    </a>
                                </li>
                                <li role="separator" class="divider" *ngIf="!printHidden || !downloadHidden"></li>
                                <li *ngIf="!printHidden" [class.disabled]="printDisabled">
                                    <a href class="dropdown-item print" (click)="!printDisabled && print.emit()">
                                        <i class="fa fa-print fw"></i>&nbsp; Print
                                    </a>
                                </li>
                                <li *ngIf="!downloadHidden" [class.disabled]="downloadDisabled">
                                    <a href class="dropdown-item download" (click)="!downloadDisabled && download.emit()">
                                        <i class="fa fa-download fw"></i>&nbsp; Download
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </nav>
    </div>
</div>

<ng-template #searchTemplate>
    <div class="search">
        <i class="fa fa-search icon"></i>
        <input type="text" class="form-control" [formControl]="searchControl" (keyup.enter)="find(true)" />
        <button class="btn btn-default" type="button" (click)="searchControl.reset();">
            <i class="fa fa-times-circle"></i>
        </button>
    </div>
    <button type="button" class="btn btn-default" tooltip="Search Backward" (click)="find(true, true)">
        <i class="fa fa-backward fa-fw"></i>
    </button>
    <button type="button" class="btn btn-default" tooltip="Search Forward" (click)="find(true)">
        <i class="fa fa-forward fa-fw"></i>
    </button>
</ng-template>
