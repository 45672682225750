<ng-template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Error</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="clear()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>{{error}}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary pull-right" (click)="clear()">
            Close
        </button>
    </div>
</ng-template>
