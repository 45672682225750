import { Directive, Input } from '@angular/core';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[href]',
    // tslint:disable-next-line:use-host-property-decorator
    host: {
        '(click)': 'preventDefault($event)'
    }
})
export class HrefDirective {
    @Input() public href: any;

    public preventDefault(event: any) {
        if (!this.href.length) {
            event.preventDefault();
        }
    }
}
