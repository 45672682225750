import { Subject } from 'rxjs';
import { PDFDocumentProxy, PDFPromise } from 'pdfjs-dist';

let activeService: PrintService = null;
const print = window.print;

export const CSS_UNITS = 115.0 / 72.0;

export class PrintService {

    currentPage: number;
    scratchCanvas: any;
    pageStyleSheet: HTMLStyleElement;
    progress$ = new Subject<number>();

    constructor(
        private pdfDocument: PDFDocumentProxy,
        private pagesOverview: any,
        private printContainer: any
    ) {
        this.currentPage = -1;
        // The temporary canvas where renderPage paints one page at a time.
        this.scratchCanvas = document.createElement('canvas');

        activeService = this;
    }

    layout() {
        this.throwIfInactive();

        const body = document.querySelector('body');
        body.setAttribute('data-pdfjsprinting', 'true');

        const hasEqualPageSizes = this.pagesOverview.every(function (size) {
            return size.width === this.pagesOverview[0].width &&
                size.height === this.pagesOverview[0].height;
        }, this);
        if (!hasEqualPageSizes) {
            console.warn('Not all pages have the same size. The printed result may be incorrect!');
        }

        // Insert a @page + size rule to make sure that the page size is correctly
        // set. Note that we assume that all pages have the same size, because
        // variable-size pages are not supported yet (e.g. in Chrome & Firefox).
        // TODO(robwu): Use named pages when size calculation bugs get resolved
        // (e.g. https://crbug.com/355116) AND when support for named pages is
        // added (http://www.w3.org/TR/css3-page/#using-named-pages).
        // In browsers where @page + size is not supported (such as Firefox,
        // https://bugzil.la/851441), the next stylesheet will be ignored and the
        // user has to select the correct paper size in the UI if wanted.
        this.pageStyleSheet = document.createElement('style');
        const pageSize = this.pagesOverview[0];
        this.pageStyleSheet.textContent =
            // "size:<width> <height>" is what we need. But also add "A4" because
            // Firefox incorrectly reports support for the other value.
            '@supports ((size:A4) and (size:1pt 1pt)) {' +
            '@page { size: ' + pageSize.width + 'pt ' + pageSize.height + 'pt;}' +
            '}';
        body.appendChild(this.pageStyleSheet);
    }

    destroy() {
        if (activeService !== this) {
            // |activeService| cannot be replaced without calling destroy() first,
            // so if it differs then an external consumer has a stale reference to
            // us.
            return;
        }
        this.printContainer.textContent = '';
        if (this.pageStyleSheet) {
            this.pageStyleSheet.remove();
            this.pageStyleSheet = null;
        }
        this.scratchCanvas.width = this.scratchCanvas.height = 0;
        this.scratchCanvas = null;
        activeService = null;
    }

    renderPages() {
        const pageCount = this.pagesOverview.length;
        const renderNextPage = (resolve, reject) => {
            this.throwIfInactive();
            if (++this.currentPage >= pageCount) {
                this.renderProgress(pageCount, pageCount);
                resolve();
                return;
            }
            const index = this.currentPage;
            this.renderProgress(index, pageCount);
            renderPage(this, this.pdfDocument, index + 1, this.pagesOverview[index])
                .then(this.useRenderedPage.bind(this))
                .then(function () {
                    renderNextPage(resolve, reject);
                }, reject);
        };
        return new Promise(renderNextPage);
    }

    renderProgress(index, total) {
        const progress = Math.round(100 * index / total);
        this.progress$.next(progress);
        if (progress >= 100) {
            this.progress$.complete();
        }
    }

    useRenderedPage(printItem) {
        this.throwIfInactive();
        const img = document.createElement('img');
        img.style.width = printItem.width;
        img.style.height = printItem.height;

        const scratchCanvas = this.scratchCanvas;
        if (('toBlob' in scratchCanvas)) {
            scratchCanvas.toBlob(function (blob) {
                img.src = URL.createObjectURL(blob);
            });
        } else {
            img.src = scratchCanvas.toDataURL();
        }

        const wrapper = document.createElement('div');
        wrapper.appendChild(img);
        this.printContainer.appendChild(wrapper);

        return new Promise(function (resolve, reject) {
            img.onload = resolve;
            img.onerror = reject;
        });
    }

    performPrint() {
        this.throwIfInactive();
        return new Promise((resolve) => {
            // Push window.print in the macrotask queue to avoid being affected by
            // the deprecation of running print() code in a microtask, see
            // https://github.com/mozilla/pdf.js/issues/7547.
            setTimeout(() => {
                if (!this.active) {
                    resolve(null);
                    return;
                }
                print.call(window);
                // Delay promise resolution in case print() was not synchronous.
                setTimeout(resolve, 20);  // Tidy-up.
            }, 0);
        });
    }

    get active() {
        return this === activeService;
    }

    throwIfInactive() {
        if (!this.active) {
            throw new Error('This print request was cancelled or completed.');
        }
    }
}

// Renders the page to the canvas of the given print service, and returns
// the suggested dimensions of the output page.
export function renderPage(activeServiceOnEntry: PrintService, pdfDocument: PDFDocumentProxy, pageNumber: number, size: any) {
    const scratchCanvas = activeService.scratchCanvas;

    // The size of the canvas in pixels for printing.
    const PRINT_RESOLUTION = 150;
    const PRINT_UNITS = PRINT_RESOLUTION / 72.0;
    scratchCanvas.width = Math.floor(size.width * PRINT_UNITS);
    scratchCanvas.height = Math.floor(size.height * PRINT_UNITS);

    // The physical size of the img as specified by the PDF document.
    const width = Math.floor(size.width * CSS_UNITS) + 'px';
    const height = Math.floor(size.height * CSS_UNITS) + 'px';

    const ctx = scratchCanvas.getContext('2d');
    ctx.save();
    ctx.fillStyle = 'rgb(255, 255, 255)';
    ctx.fillRect(0, 0, scratchCanvas.width, scratchCanvas.height);
    ctx.restore();

    return pdfDocument.getPage(pageNumber).then(function (pdfPage) {
        const renderContext = {
            canvasContext: ctx,
            transform: [PRINT_UNITS, 0, 0, PRINT_UNITS, 0, 0],
            viewport: pdfPage.getViewport({ scale: 1, rotation: size.rotation }),
            intent: 'print',
        };
        return pdfPage.render(renderContext)['promise'];
    }).then(function () {
        return {
            width,
            height,
        };
    });
}

export function abort() {
    if (activeService) {
        activeService.destroy();
        dispatchEvent('afterprint');
    }
}

export function dispatchEvent(eventType) {
    const event = document.createEvent('CustomEvent');
    event.initCustomEvent(eventType, false, false, 'custom');
    window.dispatchEvent(event);
}

export function initialize() {
    // tslint:disable-next-line:no-shadowed-variable
    window.print = function print() {
        if (activeService) {
            console.warn('Ignored window.print() because of a pending print job.');
            return;
        }

        try {
            dispatchEvent('beforeprint');
        } finally {
            if (!activeService) {
                console.error('Expected print service to be initialized.');
                dispatchEvent('afterprint');
                return;
            }

            const activeServiceOnEntry = activeService;
            activeService.renderPages()
                .then(function () {
                    return activeServiceOnEntry.performPrint();
                }).catch(function () {
                    // Ignore any error messages.
                }).then(function () {
                    // aborts acts on the "active" print request, so we need to check
                    // whether the print request (activeServiceOnEntry) is still active.
                    // Without the check, an unrelated print request (created after aborting
                    // this print request while the pages were being generated) would be
                    // aborted.
                    if (activeServiceOnEntry.active) {
                        abort();
                    }
                });
        }
    };

    const hasAttachEvent = !!document['attachEvent'];

    window.addEventListener('keydown', function (event) {
        // Intercept Cmd/Ctrl + P in all browsers.
        // Also intercept Cmd/Ctrl + Shift + P in Chrome and Opera
        if (event.keyCode === /* P= */ 80 && (event.ctrlKey || event.metaKey) &&
            !event.altKey && (!event.shiftKey || window['chrome'] || window['opera'])) {
            window.print();
            if (hasAttachEvent) {
                // Only attachEvent can cancel Ctrl + P dialog in IE <=10
                // attachEvent is gone in IE11, so the dialog will re-appear in IE11.
                return;
            }
            event.preventDefault();
            if (event.stopImmediatePropagation) {
                event.stopImmediatePropagation();
            } else {
                event.stopPropagation();
            }
            return;
        }
    }, true);
    if (hasAttachEvent) {
        document['attachEvent']('onkeydown', function (event) {
            event = event || window.event;
            if (event.keyCode === /* P= */ 80 && event.ctrlKey) {
                event.keyCode = 0;
                return false;
            }
        });
    }

    if ('onbeforeprint' in window) {
        // Do not propagate before/afterprint events when they are not triggered
        // from within this polyfill. (FF /IE / Chrome 63+).
        const stopPropagationIfNeeded = function (event) {
            if (event.detail !== 'custom' && event.stopImmediatePropagation) {
                event.stopImmediatePropagation();
            }
        };
        window.addEventListener('beforeprint', stopPropagationIfNeeded);
        window.addEventListener('afterprint', stopPropagationIfNeeded);
    }
}
