
import { Directive, ElementRef, Input, OnInit } from '@angular/core';

// tslint:disable-next-line:directive-selector
@Directive({ selector: '[scrollToWhen]' })
export class ScrollToWhenDirective implements OnInit {
    constructor(private element: ElementRef) { }

    private _container: any;

    ngOnInit() {
        this._container = this._container || this.element.nativeElement.parentNode;
    }

    @Input()
    set scrollToWhen(condition: boolean) {
        if (condition) {
            this.scrollToElement();
        }
    }

    @Input()
    set scrollContainer(container: string | Element) {
        if (container instanceof Element) {
            this._container = container;
        } else if (isString(container)) {
            this._container = document.querySelector(container) || this.element.nativeElement.parentNode;
        } else {
            this._container = this.element.nativeElement.parentNode;
        }
    }

    private scrollToElement() {
        if (this._container) {
            setTimeout(() => this._container.scrollTop = this.element.nativeElement.offsetTop);
        }
    }
}

function isString(x) {
    return Object.prototype.toString.call(x) === '[object String]';
}
