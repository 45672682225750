import { Component, TemplateRef, ViewChild, Input, Output, EventEmitter, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'pdf-printer-progress',
    templateUrl: './printer-progress.component.html',
    styleUrls: ['./printer-progress.component.css'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrinterProgressComponent {

    @Input() progress: number;

    @Output() cancel = new EventEmitter();

    @ViewChild(TemplateRef)
    private template: TemplateRef<any>;
    private modal: BsModalRef;

    constructor(private modalService: BsModalService) { }

    open() {
        this.modal = this.modalService.show(this.template, {
            class: 'printer-progress',
            backdrop: false,
            ignoreBackdropClick: true,
            keyboard: false
        });
    }

    close() {
        this.modal.hide();
    }
}
