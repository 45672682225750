import {
    Component,
    Input,
    EventEmitter,
    Output, OnChanges,
    SimpleChanges,
    ViewChild,
    TemplateRef,
    ViewEncapsulation,
    ChangeDetectionStrategy
} from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'pdf-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.css'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorComponent implements OnChanges {

    @Input() error: string;

    @Output() errorChange = new EventEmitter<string>();

    @ViewChild(TemplateRef)
    private template: TemplateRef<any>;
    private modal: BsModalRef;

    constructor(private modalService: BsModalService) { }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.error.currentValue) {
            this.show();
        } else {
            this.hide();
        }
    }

    show() {
        this.modal = this.modalService.show(this.template, {
            class: 'pdf-error',
            backdrop: 'static',
            ignoreBackdropClick: true,
            keyboard: false
        });
    }

    hide() {
        if (this.modal) {
            this.modal.hide();
        }
    }

    clear() {
        this.error = null;
        this.errorChange.emit(null);
    }
}
