// tslint:disable:no-input-rename
import {
    Component,
    Input,
    OnChanges,
    SimpleChanges,
    ViewEncapsulation,
    Output,
    EventEmitter,
    OnDestroy,
    ViewChild,
    ChangeDetectorRef
} from '@angular/core';
import { Subscription } from 'rxjs';
import { PDFSource } from 'pdfjs-dist';
import { DownloadManager } from 'pdfjs-dist/lib/web/download_manager';
import { getPDFFileNameFromURL } from 'pdfjs-dist/lib/web/ui_utils';
import { createBlob } from 'pdfjs-dist/lib/shared/util';

import { PdfService } from '../services/pdf.service';
import { PdfDocument } from '../models/pdf-document';
import { PageEvent } from '../models/page-event';
import { PageRotationEvent } from '../models/page-rotation-event';
import { ViewerPreference } from './toolbar.component';
import { ViewerComponent } from './viewer.component';
import { PrinterComponent } from './printer.component';
import { ThumbnailsComponent } from './thumbnails.component';

@Component({
    selector: 'pdf-viewer',
    templateUrl: './pdf-viewer.component.html',
    styleUrls: ['./pdf-viewer.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class PdfViewerComponent implements OnChanges, OnDestroy {

    @Input() src: string | Uint8Array | PDFSource;
    @Input() zoom?: number | 'page-width' | 'page-height' | 'page-fit' | 'auto';
    @Input() cache = false;
    @Input('print') printPreference = ViewerPreference.enabled;
    @Input('download') downloadPreference = ViewerPreference.enabled;
    @Input('rotate') rotatePreference = ViewerPreference.enabled;
    @Input('remove') removePreference = ViewerPreference.enabled;
    @Input('paging') pagingPreference = ViewerPreference.enabled;
    @Input('thumbnails') thumbnailsPreference = ViewerPreference.enabled;

    @Output() load = new EventEmitter<void>();
    @Output() pagesInit = new EventEmitter<void>();
    @Output() pagesLoaded = new EventEmitter<void>();
    @Output() pageRemove = new EventEmitter<PageEvent>();
    @Output() pageRotate = new EventEmitter<PageRotationEvent>();
    @Output() documentReload = new EventEmitter<void>();
    @Output() documentPrint = new EventEmitter<void>();
    @Output() documentDownload = new EventEmitter<void>();

    pdf: PdfDocument;
    currentPage = 0;
    scale = 1;
    error: string;
    printProgress = 0;

    @ViewChild(ViewerComponent) private viewer: ViewerComponent;
    @ViewChild(ThumbnailsComponent) private thumbnails: ThumbnailsComponent;
    @ViewChild(PrinterComponent) private printer: PrinterComponent;

    private downloadComplete = false;
    private contentDispositionFilename: any;
    private downloadManager: any;
    private subscriptions: Subscription[] = [];

    constructor(private pdfService: PdfService, private cdr: ChangeDetectorRef) {
        this.downloadManager = new DownloadManager({ disableCreateObjectURL: false });
        this.subscriptions.push(
            this.pdfService.loadComplete$.subscribe(x => {
                this.load.emit();
                this.downloadComplete = false;
                this.contentDispositionFilename = null;
                this.pdf = x;
                this.pdf.proxy['getDownloadInfo']().then(() => {
                    this.downloadComplete = true;
                });
                this.pdf.proxy.getMetadata().then(y => {
                    this.contentDispositionFilename = y['contentDispositionFilename'];
                });
            })
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('src' in changes) {
            if (!this.cache && changes.src.previousValue !== changes.src.currentValue && this.pdf) {
                this.pdf.destroy();
            }
            this.open(this.src);
        }
        if ('zoom' in changes && changes.zoom.currentValue) {
            this.setScale();
        }
    }

    ngOnDestroy() {
        if (!this.cache && this.pdf) {
            this.pdf.destroy();
        }
        this.clearCache();
        this.subscriptions.forEach(x => x.unsubscribe());
        this.subscriptions = [];
    }

    open(src: string | Uint8Array | PDFSource) {
        if (!src) {
            if (!this.cache && this.pdf) {
                this.pdf.destroy();
            }
            this.pdf = null;
            return;
        }
        this.pdfService.load(src, this.cache);
    }

    rotate(event: PageRotationEvent, emit = true) {
        if (this.pdf) {
            this.pdf.rotatePage(event.pageNumber, event.rotation);
        }
        if (this.viewer) {
            this.viewer.rotatePage(event);
        }
        if (this.thumbnails) {
            this.thumbnails.rotatePage(event);
        }
        if (emit) {
            this.pageRotate.emit(event);
        }
    }

    rotateAll(rotation: number, emit = true) {
        if (this.pdf) {
            this.pdf.rotateAllPages(rotation);
            if (emit) {
                this.pdf.pages$.value.forEach(page => {
                    this.pageRotate.emit({
                        pageNumber: page.pageNumber,
                        rotation
                    });
                });
            }
        }
        if (this.viewer) {
            this.viewer.rotateAllPages(rotation);
        }
        if (this.thumbnails) {
            this.thumbnails.rotateAllPages(rotation);
        }
    }

    remove(event: PageEvent, emit = true) {
        if (this.pdf) {
            this.pdf.removePage(event.pageNumber);
        }
        if (this.viewer) {
            this.viewer.removePage(event);
        }
        if (this.thumbnails) {
            this.thumbnails.removePage(event);
        }
        if (emit) {
            this.pageRemove.emit(event);
        }
    }

    reload(emit = true) {
        if (!this.cache && this.pdf) {
            this.pdf.destroy();
        }
        this.pdfService.load(this.src);
        this.setScale();
        this.cdr.detectChanges();
        if (emit) {
            this.documentReload.emit();
        }
    }

    print(emit = true) {
        this.printer.print();
        if (emit) {
            this.documentPrint.emit();
        }
    }

    download(emit = true) {
        const self = this;
        if (!this.pdf) { return; }

        function downloadByUrl() {
            downloadManager.downloadUrl(url, filename);
            if (emit) {
                self.documentPrint.emit();
            }
        }

        const url = this.pdf.url;
        // Use this.url instead of this.baseUrl to perform filename detection based
        // on the reference fragment as ultimate fallback if needed.
        const filename = this.contentDispositionFilename || getPDFFileNameFromURL(url);
        const downloadManager = this.downloadManager;
        downloadManager.onerror = (err) => {
            // This error won't really be helpful because it's likely the
            // fallback won't work either (or is already open).
            this.error = `PDF failed to download: ${err}`;
        };

        // When the PDF document isn't ready, or the PDF file is still downloading,
        // simply download using the URL.
        if (!this.pdf || !this.downloadComplete) {
            downloadByUrl();
            return;
        }

        this.pdf.proxy.getData().then(function (data) {
            const blob = new Blob([data], {type: 'application/pdf'});
            downloadManager.download(blob, url, filename);
            if (emit) {
                self.documentPrint.emit();
            }
        }, downloadByUrl); // Error occurred, try downloading with the URL.
    }

    clearCache() {
        this.pdfService.clearCache();
    }

    private setScale() {
        // If the zoomArg is a number, it has to get divided by 100. If it's
        // a string, it should stay as it is.
        const zoom = this.zoom;
        const zoomNumber = parseFloat(zoom as any);
        const scale = zoomNumber ? zoomNumber / 100 : zoom;
        this.scale = scale as any || 1;
    }
}
