import {
    Component,
    ViewEncapsulation,
    Input,
    ChangeDetectionStrategy,
    OnInit,
    ElementRef,
    Inject,
    OnChanges,
    SimpleChanges
} from '@angular/core';
import { PDFThumbnailViewer } from 'pdfjs-dist/lib/web/pdf_thumbnail_viewer';
import { getVisibleElements } from 'pdfjs-dist/lib/web/ui_utils';

import { LinkService, RenderingQueue } from '../services/tokens';
import { PDFDocumentProxy } from 'pdfjs-dist';
import { PageRotationEvent } from '../models/page-rotation-event';
import { PageEvent } from '../models/page-event';

@Component({
    selector: 'pdf-thumbnails',
    templateUrl: './thumbnails.component.html',
    styleUrls: ['./thumbnails.component.css'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThumbnailsComponent implements OnInit, OnChanges {

    @Input() proxy: PDFDocumentProxy;
    @Input() currentPage: number;

    thumbnailViewer: any;
    isOpen = false;

    constructor(
        private element: ElementRef,
        @Inject(LinkService) private linkService: any,
        @Inject(RenderingQueue) private renderingQueue: any
    ) { }

    ngOnInit() {
        this.setupThumbnailViewer();
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('proxy' in changes) {
            this.render();
        }
        if ('currentPage' in changes) {
            if (this.thumbnailViewer && this.isOpen) {
                this.thumbnailViewer.scrollThumbnailIntoView(this.currentPage);
            }
        }
    }

    toggleThumbs() {
        this.isOpen = !this.isOpen;
        this.renderingQueue.isThumbnailViewEnabled = this.isOpen;
        if (this.isOpen) {
            setTimeout(() => this.thumbnailViewer.forceRendering(), 0);
        }
    }

    rotatePage(event: PageRotationEvent) {
        if (!this.proxy) { return; }
        const pageView = this.thumbnailViewer._thumbnails[event.pageNumber - 1];
        const rotation = (pageView.rotation + 360 + event.rotation) % 360;
        pageView.update(rotation);
    }

    rotateAllPages(rotation: number) {
        if (!this.proxy) { return; }
        this.thumbnailViewer._thumbnails.forEach(pageView => {
            const delta = (pageView.rotation + 360 + rotation) % 360;
            pageView.update(delta);
        });
    }

    removePage(event: PageEvent) {
        if (!this.proxy) { return; }
        const pageView = this.thumbnailViewer._thumbnails[event.pageNumber - 1];
        this.hidePage(pageView);
    }

    private setupThumbnailViewer() {
        this.thumbnailViewer = new PDFThumbnailViewer({
            container: this.element.nativeElement.querySelector('div.thumbnails'),
            linkService: this.linkService,
            renderingQueue: this.renderingQueue,
        });

        this.renderingQueue.setThumbnailViewer(this.thumbnailViewer);

        this.thumbnailViewer['_getVisibleThumbs'] = () => {
            const thumbs = this.thumbnailViewer._thumbnails.filter(x => !x.pdfPage || !x.pdfPage._pageInfo.removed);
            return getVisibleElements(this.thumbnailViewer.container, thumbs);
        };
    }

    private render() {
        if (this.thumbnailViewer) {
            if (this.proxy) {
                this.thumbnailViewer.setDocument(this.proxy);
            } else {
                this.thumbnailViewer.setDocument(null);
            }
        }
    }

    private hidePage(pageView: any) {
        pageView.div.style.display = 'none';
    }
}
