<ng-template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Preparing document for printing...</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="cancel.emit()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="progress">
            <div class="progress-bar" role="progressbar" [attr.aria-valuenow]="progress" aria-valuemin="0" aria-valuemax="100" [style.width]="progress + '%'">
                <span *ngIf="progress !== 0">{{progress}}%</span>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary pull-right" (click)="cancel.emit()">
            Cancel
        </button>
    </div>
</ng-template>
