import { PDFDocumentProxy } from 'pdfjs-dist';
import { from, BehaviorSubject } from 'rxjs';
import { findIndex, clone } from 'lodash-es';
import { PdfPage } from './pdf-page';

export class PdfDocument {

    pages$ = new BehaviorSubject<PdfPage[]>([]);
    numPages$ = new BehaviorSubject<number>(0);

    get numPages() {
        return this._pages.length;
    }

    private _pages: PdfPage[];

    constructor(public proxy: PDFDocumentProxy, public url?: string) {
        const pagePromises = Array.from({ length: this.proxy.numPages }, (x, i) => proxy.getPage(i + 1));
        from(Promise.all(pagePromises as any)).subscribe((x: any) => {
            const pages = x.map(y => new PdfPage(y)).filter(y => !y.removed);
            this.emit(pages);
        });
    }

    rotatePage(pageNumber: number, rotation: number) {
        const pages = this._pages.map(x => {
            if (x.pageNumber === pageNumber) {
                const page = clone(x);
                page.rotate(rotation);
                return page;
            }
            return x;
        });
        this.emit(pages);
    }

    rotateAllPages(rotation: number) {
        const pages = this._pages.map(x => {
            const page = new PdfPage(x.proxy);
            page.rotate(rotation);
            return page;
        });
        this.emit(pages);
    }

    removePage(pageNumber: number) {
        const index = findIndex(this._pages, x => x.pageNumber === pageNumber);
        const page = this._pages.splice(index, 1)[0];
        page.remove();
        this.emit(this._pages);
    }

    destroy() {
        this.pages$.complete();
        this.numPages$.complete();
        this.proxy.destroy();
    }

    private emit(pages) {
        this._pages = [...pages];
        this.pages$.next(this._pages);
        this.numPages$.next(this._pages.length);
    }
}
